import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  HomeIcon,
  InformationCircleIcon,
  UserIcon,
  ViewBoardsIcon,
  ViewGridIcon,
} from "@heroicons/react/outline";

export default function Footer() {
  const { pathname } = useLocation();

  return (
    <div className="w-full bg-gray-900 border-t py-2 flex items-center justify-center overflow-x-auto flex-shrink-0 lg:hidden">
      <ul className="flex items-center justify-between sm:justify-center space-x-4 sm:space-x-8 w-full px-4 overflow-x-auto text-white">
        <li>
          <NavLink
            to="/"
            exact
            activeClassName="nav-footer-item-active"
            className={`flex flex-col items-center cursor-pointer whitespace-nowrap`}
          >
            <div>
              <HomeIcon className="w-5 h-5" />
            </div>
            <div className="font-medium text-xs">Dashboard</div>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/all-trips"
            activeClassName="nav-footer-item-active"
            className={`flex flex-col items-center cursor-pointer whitespace-nowrap`}
          >
            <div>
              <ViewGridIcon className="w-5 h-5" />
            </div>
            <div className="font-medium text-xs">All Trips</div>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/operations"
            activeClassName="nav-footer-item-active"
            className={`flex flex-col items-center cursor-pointer whitespace-nowrap`}
          >
            <div>
              <ViewBoardsIcon className="w-5 h-5" />
            </div>
            <div className="font-medium text-xs">Operations</div>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/alarming-trips"
            activeClassName="nav-footer-item-active"
            className={`flex flex-col items-center cursor-pointer whitespace-nowrap`}
          >
            <div>
              <InformationCircleIcon className="w-5 h-5" />
            </div>
            <div className="font-medium text-xs">Alarming Trips</div>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/profile"
            activeClassName="nav-footer-item-active"
            className={`flex flex-col items-center cursor-pointer whitespace-nowrap`}
          >
            <div>
              <UserIcon className="w-5 h-5" />
            </div>
            <div className="font-medium text-xs">Profile</div>
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
