import { Link } from "react-router-dom";

import { ServerIcon } from "@heroicons/react/outline";

export default function Dashboard() {
  return (
    <div className="p-4 space-y-4 flex flex-col flex-1 overflow-y-auto min-w-0">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <h2 className="text-2xl sm:text-4xl font-bold text-theme-dark">
            Hi Admin
          </h2>
          <p className="text-sm text-gray-500">Welcome Back !</p>
        </div>
        <div className="col-span-6 sm:col-span-4 lg:col-span-3 2xl:col-span-2">
          <Link to="/operations/#markReady">
            <div className="px-2 py-6 bg-white shadow rounded-3xl ring-2 ring-blue-100 cursor-pointer">
              <div className="text-center flex flex-col items-center space-y-2">
                <div className="rounded-full p-2 bg-blue-50">
                  <ServerIcon className="h-6 w-6 text-blue-500" />
                </div>
                <div className="w-full">
                  <div className="font-bold text-2xl truncate text-theme-dark">
                    08
                  </div>
                  <div className="font-medium text-xs truncate text-gray-500">
                    Mark Ready
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-span-6 sm:col-span-4 lg:col-span-3 2xl:col-span-2">
          <Link to="/operations/#alarmingTrips">
            <div className="px-2 py-6 bg-white shadow rounded-3xl ring-2 ring-blue-100 cursor-pointer">
              <div className="text-center flex flex-col items-center space-y-2">
                <div className="rounded-full p-2 bg-blue-50">
                  <ServerIcon className="h-6 w-6 text-blue-500" />
                </div>
                <div className="w-full">
                  <div className="font-bold text-2xl truncate text-theme-dark">
                    02
                  </div>
                  <div className="font-medium text-xs truncate text-gray-500">
                    Alarming
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-span-6 sm:col-span-4 lg:col-span-3 2xl:col-span-2">
          <Link to="/operations/#activeTrips">
            <div className="px-2 py-6 bg-white shadow rounded-3xl ring-2 ring-blue-100 cursor-pointer">
              <div className="text-center flex flex-col items-center space-y-2">
                <div className="rounded-full p-2 bg-blue-50">
                  <ServerIcon className="h-6 w-6 text-blue-500" />
                </div>
                <div className="w-full">
                  <div className="font-bold text-2xl truncate text-theme-dark">
                    32
                  </div>
                  <div className="font-medium text-xs truncate text-gray-500">
                    Active
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-span-6 sm:col-span-4 lg:col-span-3 2xl:col-span-2">
          <Link to="/operations/#scheduledTrips">
            <div className="px-2 py-6 bg-white shadow rounded-3xl ring-2 ring-blue-100 cursor-pointer">
              <div className="text-center flex flex-col items-center space-y-2">
                <div className="rounded-full p-2 bg-blue-50">
                  <ServerIcon className="h-6 w-6 text-blue-500" />
                </div>
                <div className="w-full">
                  <div className="font-bold text-2xl truncate text-theme-dark">
                    155
                  </div>
                  <div className="font-medium text-xs truncate text-gray-500">
                    Schedule
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-span-6 sm:col-span-4 lg:col-span-3 2xl:col-span-2">
          <Link to="/operations/#canceledTrips">
            <div className="px-2 py-6 bg-white shadow rounded-3xl ring-2 ring-blue-100 cursor-pointer">
              <div className="text-center flex flex-col items-center space-y-2">
                <div className="rounded-full p-2 bg-blue-50">
                  <ServerIcon className="h-6 w-6 text-blue-500" />
                </div>
                <div className="w-full">
                  <div className="font-bold text-2xl truncate text-theme-dark">
                    04
                  </div>
                  <div className="font-medium text-xs truncate text-gray-500">
                    Canceled
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-span-6 sm:col-span-4 lg:col-span-3 2xl:col-span-2">
          <Link to="/operations/#completedTrips">
            <div className="px-2 py-6 bg-white shadow rounded-3xl ring-2 ring-blue-100 cursor-pointer">
              <div className="text-center flex flex-col items-center space-y-2">
                <div className="rounded-full p-2 bg-blue-50">
                  <ServerIcon className="h-6 w-6 text-blue-500" />
                </div>
                <div className="w-full">
                  <div className="font-bold text-2xl truncate text-theme-dark">
                    380
                  </div>
                  <div className="font-medium text-xs truncate text-gray-500">
                    Completed
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
