import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { signin } from "../utills/networkUtils/authentication";
import { messageService } from "../utills/shared.service";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
export default function Signin({ setToken }) {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    signin(data).then((res) => {
      if (res && res.success) {
        messageService.sendMessage(res.user);
        // Swal.fire("Alert", res.message, "success");
        reset();
        setToken(res.token);
      } else if (res) {
        Swal.fire("Alert", res.message, "error");
      }
    });
  };

  return (
    <div>
      <main className="flex flex-col min-h-screen justify-center items-center bg-signin bg-cover bg-right-bottom bg-no-repeat px-4 py-8">
        <div className="w-full max-w-sm mx-auto">
          <img
            alt="logo"
            src="https://nemtplatform.com/img/logo.png"
            className="h-14 w-auto mx-auto mb-12"
          />
          <h1 className="sr-only">Log in to your Tailwind UI account</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            {errors.email && errors.password && (
              <p className="mt-16 mb-6 text-xs text-center font-medium text-red-600">
                These credentials do not match our records.
              </p>
            )}
            <div className="grid grid-cols-1 gap-5">
              <div className="relative">
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <input
                  type="email"
                  className={`${
                    errors.email ? "ring-2 ring-red-500" : ""
                  } border-gray-200 rounded-md w-full shadow text-sm bg-white`}
                  {...register("email", { required: true })}
                  placeholder="Email Address"
                />
                {errors.email && (
                  <span className="absolute inset-y-0 right-0 flex items-center mr-3">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-600" />
                  </span>
                )}
              </div>
              <div className="relative">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  type="password"
                  className={`${
                    errors.password ? "ring-2 ring-red-500" : ""
                  } border-gray-200 rounded-md w-full shadow text-sm bg-white`}
                  {...register("password", { required: true })}
                  placeholder="Password"
                />
                {errors.password && (
                  <span className="absolute inset-y-0 right-0 flex items-center mr-3">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-600" />
                  </span>
                )}
              </div>
              <div className="relative">
                <button
                  type="submit"
                  className="rounded-md border w-full py-2 px-4 text-white bg-blue-600 font-medium focus:ring-2 ring-offset-2 ring-blue-500 text-sm"
                >
                  Sign in to account
                </button>
              </div>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
}

Signin.propTypes = {
  setToken: PropTypes.func.isRequired,
};
