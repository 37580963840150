const { SERVER_API_URL } = require("../constant");

// const fetchSmsList = () => {
//   return fetch(`${SERVER_API_URL}/scheduleSms`, {
//     headers: { Authorization: `JWT ${localStorage.getItem("token")}` },
//   })
//     .then((res) => res.json())
//     .catch((error) => console.log("Get SMS List Error", error));
// };

// const scheduleSms = (payload) => {
//   return fetch(`${SERVER_API_URL}/scheduleSms`, {
//     method: "post",
//     headers: {
//       Authorization: `JWT ${localStorage.getItem("token")}`,
//       "content-type": "application/json",
//     },
//     body: JSON.stringify(payload),
//   })
//     .then((res) => res.json())
//     .catch((error) => console.log("Schedule SMS  Error", error));
// };

const signin = (payload) => {
  return fetch(`${SERVER_API_URL}/auth/signin`, {
    method: "post",
    headers: {
      // Authorization: `JWT ${localStorage.getItem("token")}`,
      "content-type": "application/json",
    },
    body: JSON.stringify({...payload, roles : "quarter"}),
  })
    .then((res) => res.json())
    .catch((error) => console.log("Signin  Error", error));
};

const validateAuthToken = () => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(`${SERVER_API_URL}/users/me`, requestOptions)
    .then((response) => response.json())
    .catch((err) => {
      console.log("Me Request Error ", err);
    });
};

export { signin, validateAuthToken };
