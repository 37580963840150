import { Link } from "react-router-dom";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  DocumentTextIcon,
  DotsVerticalIcon,
  OfficeBuildingIcon,
  LocationMarkerIcon,
  PlusIcon,
  TagIcon,
  TruckIcon,
} from "@heroicons/react/outline";



export default function Operations() {
  return (
    <div className="p-4 space-y-4 flex flex-col flex-1 overflow-hidden min-w-0">
    <div className="flex flex-1 space-x-2 overflow-auto">
      <div className="w-[95%] sm:w-80 h-full bg-white border rounded shadow flex flex-col flex-shrink-0" id="markReadyTrips">
        <div className="flex bg-gray-50 rounded-t justify-between p-1">
          <div className="text-sm text-theme-dark font-bold">
            Marked Ready
          </div>
          <div className="rounded text-xs px-1 py-0.5 bg-red-100 text-red-500 font-medium">
            06
          </div>
        </div>
        <div className="flex flex-col flex-1 overflow-y-auto p-2 space-y-2">
          <div className="border shadow rounded-lg bg-white divide-y bg-markready bg-fill bg-no-repeat bg-center">
            <div className="p-2 flex items-start justify-between space-x-1">
              <div className="flex flex-wrap justify-between items-start w-full">
                <div>
                  <div className="flex items-center space-x-1">
                    <div className="text-xs font-medium text-theme-dark max-w-[120px] truncate">
                      Hunter Pop
                    </div>
                    <div className="text-xs text-gray-400">
                      KHK4EYFW
                    </div>
                  </div>
                  <div className="text-xs text-gray-500 select-all">
                    455-57-6644
                  </div>
                </div>
                <div className="flex items-center space-x-1">
                  <div className="px-1 py-0.5 text-green-600 bg-green-100 font-medium rounded text-xs">
                    Ready 14mins ago
                  </div>
                </div>
              </div>
              <Menu as="div" className="relative">
                <Menu.Button className="py-0.5">
                  <DotsVerticalIcon className="h-4 w-4 text-theme-dark" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div class="py-1" role="none">
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Unmark Ready
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Driver
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Member
                      </a>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="p-2 grid grid-cols-2 gap-2">
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-yellow-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-red-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-start space-x-2">
                <div>
                  <OfficeBuildingIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs truncate">
                  Love Trans INC
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <TruckIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  WAV, 13 Miles
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <PlusIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  Logisticare
                </div>
              </div>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <TagIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Fetch ETA
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <DocumentTextIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Company Notes
                  </div>
                </div>
              </Link>
            </div>
            <div className="p-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <div className="flex flex-shrink-0">
                    <img
                        alt="driver"
                      src="/img/driver.jpeg"
                      className="h-8 w-8 rounded-full object-cover"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-medium text-theme-dark w-28 truncate">
                      Gilbert Miller
                    </div>
                    <div className="text-xs text-gray-500 select-all">
                      307-82-0016
                    </div>
                  </div>
                </div>
                <div className="hidden">
                  <button className="px-2 py-1 border text-xs text-white bg-blue-600 rounded font-medium focus:ring-2 ring-offset-2 ring-blue-500">
                    Re-Assign
                  </button>
                </div>
                <div className="space-x-2 flex items-center">
                  <div className="px-1 py-0.5 text-blue-600 bg-blue-100 font-medium rounded text-xs">
                    Offered
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border shadow rounded-lg bg-white divide-y bg-markready bg-fill bg-no-repeat bg-center">
            <div className="p-2 flex items-start justify-between space-x-1">
              <div className="flex flex-wrap justify-between items-start w-full">
                <div>
                  <div className="flex items-center space-x-1">
                    <div className="text-xs font-medium text-theme-dark max-w-[120px] truncate">
                      Hunter Pop
                    </div>
                    <div className="text-xs text-gray-400">
                      KHK4EYFW
                    </div>
                  </div>
                  <div className="text-xs text-gray-500 select-all">
                    455-57-6644
                  </div>
                </div>
                <div className="flex items-center space-x-1">
                  <div className="px-1 py-0.5 text-green-600 bg-green-100 font-medium rounded text-xs">
                    Ready 14mins ago
                  </div>
                </div>
              </div>
              <Menu as="div" className="relative">
                <Menu.Button className="py-0.5">
                  <DotsVerticalIcon className="h-4 w-4 text-theme-dark" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div class="py-1" role="none">
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Unmark Ready
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Driver
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Member
                      </a>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="p-2 grid grid-cols-2 gap-2">
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-yellow-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-red-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-start space-x-2">
                <div>
                  <OfficeBuildingIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs truncate">
                  Love Trans INC
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <TruckIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  WAV, 13 Miles
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <PlusIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  Logisticare
                </div>
              </div>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <TagIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Fetch ETA
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <DocumentTextIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Company Notes
                  </div>
                </div>
              </Link>
            </div>
            <div className="p-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <div className="flex flex-shrink-0">
                    <img
                    alt="driver"
                      src="/img/driver.jpeg"
                      className="h-8 w-8 rounded-full object-cover"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-medium text-theme-dark w-28 truncate">
                      Gilbert Miller
                    </div>
                    <div className="text-xs text-gray-500 select-all">
                      307-82-0016
                    </div>
                  </div>
                </div>
                <div className="hidden">
                  <button className="px-2 py-1 border text-xs text-white bg-blue-600 rounded font-medium focus:ring-2 ring-offset-2 ring-blue-500">
                    Re-Assign
                  </button>
                </div>
                <div className="space-x-2 flex items-center">
                  <div className="px-1 py-0.5 text-blue-600 bg-blue-100 font-medium rounded text-xs">
                    Offered
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border shadow rounded-lg bg-white divide-y bg-markready bg-fill bg-no-repeat bg-center">
            <div className="p-2 flex items-start justify-between space-x-1">
              <div className="flex flex-wrap justify-between items-start w-full">
                <div>
                  <div className="flex items-center space-x-1">
                    <div className="text-xs font-medium text-theme-dark max-w-[120px] truncate">
                      Hunter Pop
                    </div>
                    <div className="text-xs text-gray-400">
                      KHK4EYFW
                    </div>
                  </div>
                  <div className="text-xs text-gray-500 select-all">
                    455-57-6644
                  </div>
                </div>
                <div className="flex items-center space-x-1">
                  <div className="px-1 py-0.5 text-green-600 bg-green-100 font-medium rounded text-xs">
                    Ready 14mins ago
                  </div>
                </div>
              </div>
              <Menu as="div" className="relative">
                <Menu.Button className="py-0.5">
                  <DotsVerticalIcon className="h-4 w-4 text-theme-dark" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div class="py-1" role="none">
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Unmark Ready
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Driver
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Member
                      </a>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="p-2 grid grid-cols-2 gap-2">
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-yellow-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-red-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-start space-x-2">
                <div>
                  <OfficeBuildingIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs truncate">
                  Love Trans INC
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <TruckIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  WAV, 13 Miles
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <PlusIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  Logisticare
                </div>
              </div>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <TagIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Fetch ETA
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <DocumentTextIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Company Notes
                  </div>
                </div>
              </Link>
            </div>
            <div className="p-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <div className="flex flex-shrink-0">
                    <img
                        alt="driver"
                      src="/img/driver.jpeg"
                      className="h-8 w-8 rounded-full object-cover"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-medium text-theme-dark w-28 truncate">
                      Gilbert Miller
                    </div>
                    <div className="text-xs text-gray-500 select-all">
                      307-82-0016
                    </div>
                  </div>
                </div>
                <div className="hidden">
                  <button className="px-2 py-1 border text-xs text-white bg-blue-600 rounded font-medium focus:ring-2 ring-offset-2 ring-blue-500">
                    Re-Assign
                  </button>
                </div>
                <div className="space-x-2 flex items-center">
                  <div className="px-1 py-0.5 text-blue-600 bg-blue-100 font-medium rounded text-xs">
                    Offered
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border shadow rounded-lg bg-white divide-y bg-markready bg-fill bg-no-repeat bg-center">
            <div className="p-2 flex items-start justify-between space-x-1">
              <div className="flex flex-wrap justify-between items-start w-full">
                <div>
                  <div className="flex items-center space-x-1">
                    <div className="text-xs font-medium text-theme-dark max-w-[120px] truncate">
                      Hunter Pop
                    </div>
                    <div className="text-xs text-gray-400">
                      KHK4EYFW
                    </div>
                  </div>
                  <div className="text-xs text-gray-500 select-all">
                    455-57-6644
                  </div>
                </div>
                <div className="flex items-center space-x-1">
                  <div className="px-1 py-0.5 text-green-600 bg-green-100 font-medium rounded text-xs">
                    Ready 14mins ago
                  </div>
                </div>
              </div>
              <Menu as="div" className="relative">
                <Menu.Button className="py-0.5">
                  <DotsVerticalIcon className="h-4 w-4 text-theme-dark" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div class="py-1" role="none">
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Unmark Ready
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Driver
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Member
                      </a>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="p-2 grid grid-cols-2 gap-2">
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-yellow-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-red-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-start space-x-2">
                <div>
                  <OfficeBuildingIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs truncate">
                  Love Trans INC
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <TruckIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  WAV, 13 Miles
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <PlusIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  Logisticare
                </div>
              </div>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <TagIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Fetch ETA
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <DocumentTextIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Company Notes
                  </div>
                </div>
              </Link>
            </div>
            <div className="p-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <div className="flex flex-shrink-0">
                    <img
                        alt="driver"
                      src="/img/driver.jpeg"
                      className="h-8 w-8 rounded-full object-cover"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-medium text-theme-dark w-28 truncate">
                      Gilbert Miller
                    </div>
                    <div className="text-xs text-gray-500 select-all">
                      307-82-0016
                    </div>
                  </div>
                </div>
                <div className="hidden">
                  <button className="px-2 py-1 border text-xs text-white bg-blue-600 rounded font-medium focus:ring-2 ring-offset-2 ring-blue-500">
                    Re-Assign
                  </button>
                </div>
                <div className="space-x-2 flex items-center">
                  <div className="px-1 py-0.5 text-blue-600 bg-blue-100 font-medium rounded text-xs">
                    Offered
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border shadow rounded-lg bg-white divide-y bg-markready bg-fill bg-no-repeat bg-center">
            <div className="p-2 flex items-start justify-between space-x-1">
              <div className="flex flex-wrap justify-between items-start w-full">
                <div>
                  <div className="flex items-center space-x-1">
                    <div className="text-xs font-medium text-theme-dark max-w-[120px] truncate">
                      Hunter Pop
                    </div>
                    <div className="text-xs text-gray-400">
                      KHK4EYFW
                    </div>
                  </div>
                  <div className="text-xs text-gray-500 select-all">
                    455-57-6644
                  </div>
                </div>
                <div className="flex items-center space-x-1">
                  <div className="px-1 py-0.5 text-green-600 bg-green-100 font-medium rounded text-xs">
                    Ready 14mins ago
                  </div>
                </div>
              </div>
              <Menu as="div" className="relative">
                <Menu.Button className="py-0.5">
                  <DotsVerticalIcon className="h-4 w-4 text-theme-dark" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div class="py-1" role="none">
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Unmark Ready
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Driver
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Member
                      </a>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="p-2 grid grid-cols-2 gap-2">
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-yellow-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-red-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-start space-x-2">
                <div>
                  <OfficeBuildingIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs truncate">
                  Love Trans INC
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <TruckIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  WAV, 13 Miles
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <PlusIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  Logisticare
                </div>
              </div>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <TagIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Fetch ETA
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <DocumentTextIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Company Notes
                  </div>
                </div>
              </Link>
            </div>
            <div className="p-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <div className="flex flex-shrink-0">
                    <img
                        alt="driver"
                      src="/img/driver.jpeg"
                      className="h-8 w-8 rounded-full object-cover"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-medium text-theme-dark w-28 truncate">
                      Gilbert Miller
                    </div>
                    <div className="text-xs text-gray-500 select-all">
                      307-82-0016
                    </div>
                  </div>
                </div>
                <div className="hidden">
                  <button className="px-2 py-1 border text-xs text-white bg-blue-600 rounded font-medium focus:ring-2 ring-offset-2 ring-blue-500">
                    Re-Assign
                  </button>
                </div>
                <div className="space-x-2 flex items-center">
                  <div className="px-1 py-0.5 text-blue-600 bg-blue-100 font-medium rounded text-xs">
                    Offered
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border shadow rounded-lg bg-white divide-y bg-markready bg-fill bg-no-repeat bg-center">
            <div className="p-2 flex items-start justify-between space-x-1">
              <div className="flex flex-wrap justify-between items-start w-full">
                <div>
                  <div className="flex items-center space-x-1">
                    <div className="text-xs font-medium text-theme-dark max-w-[120px] truncate">
                      Hunter Pop
                    </div>
                    <div className="text-xs text-gray-400">
                      KHK4EYFW
                    </div>
                  </div>
                  <div className="text-xs text-gray-500 select-all">
                    455-57-6644
                  </div>
                </div>
                <div className="flex items-center space-x-1">
                  <div className="px-1 py-0.5 text-green-600 bg-green-100 font-medium rounded text-xs">
                    Ready 14mins ago
                  </div>
                </div>
              </div>
              <Menu as="div" className="relative">
                <Menu.Button className="py-0.5">
                  <DotsVerticalIcon className="h-4 w-4 text-theme-dark" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div class="py-1" role="none">
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Unmark Ready
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Driver
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Member
                      </a>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="p-2 grid grid-cols-2 gap-2">
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-yellow-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-red-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-start space-x-2">
                <div>
                  <OfficeBuildingIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs truncate">
                  Love Trans INC
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <TruckIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  WAV, 13 Miles
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <PlusIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  Logisticare
                </div>
              </div>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <TagIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Fetch ETA
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <DocumentTextIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Company Notes
                  </div>
                </div>
              </Link>
            </div>
            <div className="p-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <div className="flex flex-shrink-0">
                    <img
                        alt="driver"
                      src="/img/driver.jpeg"
                      className="h-8 w-8 rounded-full object-cover"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-medium text-theme-dark w-28 truncate">
                      Gilbert Miller
                    </div>
                    <div className="text-xs text-gray-500 select-all">
                      307-82-0016
                    </div>
                  </div>
                </div>
                <div className="hidden">
                  <button className="px-2 py-1 border text-xs text-white bg-blue-600 rounded font-medium focus:ring-2 ring-offset-2 ring-blue-500">
                    Re-Assign
                  </button>
                </div>
                <div className="space-x-2 flex items-center">
                  <div className="px-1 py-0.5 text-blue-600 bg-blue-100 font-medium rounded text-xs">
                    Offered
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[95%] sm:w-80 h-full bg-white border rounded shadow flex flex-col flex-shrink-0" id="alarmingTrips">
        <div className="flex bg-gray-50 rounded-t justify-between p-1">
          <div className="text-sm text-theme-dark font-bold">
            Alarming
          </div>
          <div className="rounded text-xs px-1 py-0.5 bg-red-100 text-red-500 font-medium">
            05
          </div>
        </div>
        <div className="flex flex-col flex-1 overflow-y-auto p-2 space-y-2">
          <div className="border shadow rounded-lg bg-red-50 divide-y bg-markready bg-fill bg-no-repeat bg-center">
            <div className="p-2 flex items-start justify-between space-x-1">
              <div className="flex flex-wrap justify-between items-start w-full">
                <div>
                  <div className="flex items-center space-x-1">
                    <div className="text-xs font-medium text-theme-dark max-w-[120px] truncate">
                      Hunter Pop
                    </div>
                    <div className="text-xs text-gray-400">
                      KHK4EYFW
                    </div>
                  </div>
                  <div className="text-xs text-gray-500 select-all">
                    455-57-6644
                  </div>
                </div>
                <div className="flex items-center space-x-1">
                  <div className="px-1 py-0.5 text-red-600 bg-red-100 font-medium rounded text-xs">
                    In 5mins
                  </div>
                </div>
              </div>
              <Menu as="div" className="relative">
                <Menu.Button className="py-0.5">
                  <DotsVerticalIcon className="h-4 w-4 text-theme-dark" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div class="py-1" role="none">
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Unmark Ready
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Driver
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Member
                      </a>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="p-2 grid grid-cols-2 gap-2">
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-yellow-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-red-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-start space-x-2">
                <div>
                  <OfficeBuildingIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs truncate">
                  Love Trans INC
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <TruckIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  WAV, 13 Miles
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <PlusIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  Logisticare
                </div>
              </div>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <TagIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Fetch ETA
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <DocumentTextIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Company Notes
                  </div>
                </div>
              </Link>
            </div>
            <div className="p-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <div className="flex flex-shrink-0">
                    <img
                        alt="driver"
                      src="/img/driver.jpeg"
                      className="h-8 w-8 rounded-full object-cover"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-medium text-theme-dark w-28 truncate">
                      Gilbert Miller
                    </div>
                    <div className="text-xs text-gray-500 select-all">
                      307-82-0016
                    </div>
                  </div>
                </div>
                <div className="hidden">
                  <button className="px-2 py-1 border text-xs text-white bg-blue-600 rounded font-medium focus:ring-2 ring-offset-2 ring-blue-500">
                    Re-Assign
                  </button>
                </div>
                <div className="space-x-2 flex items-center">
                  <div className="px-1 py-0.5 text-blue-600 bg-blue-100 font-medium rounded text-xs">
                    Offered
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border shadow rounded-lg bg-red-50 divide-y bg-markready bg-fill bg-no-repeat bg-center">
            <div className="p-2 flex items-start justify-between space-x-1">
              <div className="flex flex-wrap justify-between items-start w-full">
                <div>
                  <div className="flex items-center space-x-1">
                    <div className="text-xs font-medium text-theme-dark max-w-[120px] truncate">
                      Hunter Pop
                    </div>
                    <div className="text-xs text-gray-400">
                      KHK4EYFW
                    </div>
                  </div>
                  <div className="text-xs text-gray-500 select-all">
                    455-57-6644
                  </div>
                </div>
                <div className="flex items-center space-x-1">
                  <div className="px-1 py-0.5 text-red-600 bg-red-100 font-medium rounded text-xs">
                    In 5mins
                  </div>
                </div>
              </div>
              <Menu as="div" className="relative">
                <Menu.Button className="py-0.5">
                  <DotsVerticalIcon className="h-4 w-4 text-theme-dark" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div class="py-1" role="none">
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Unmark Ready
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Driver
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Member
                      </a>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="p-2 grid grid-cols-2 gap-2">
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-yellow-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-red-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-start space-x-2">
                <div>
                  <OfficeBuildingIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs truncate">
                  Love Trans INC
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <TruckIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  WAV, 13 Miles
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <PlusIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  Logisticare
                </div>
              </div>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <TagIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Fetch ETA
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <DocumentTextIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Company Notes
                  </div>
                </div>
              </Link>
            </div>
            <div className="p-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <div className="flex flex-shrink-0">
                    <img
                        alt="driver"
                      src="/img/driver.jpeg"
                      className="h-8 w-8 rounded-full object-cover"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-medium text-theme-dark w-28 truncate">
                      Gilbert Miller
                    </div>
                    <div className="text-xs text-gray-500 select-all">
                      307-82-0016
                    </div>
                  </div>
                </div>
                <div className="hidden">
                  <button className="px-2 py-1 border text-xs text-white bg-blue-600 rounded font-medium focus:ring-2 ring-offset-2 ring-blue-500">
                    Re-Assign
                  </button>
                </div>
                <div className="space-x-2 flex items-center">
                  <div className="px-1 py-0.5 text-blue-600 bg-blue-100 font-medium rounded text-xs">
                    Offered
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border shadow rounded-lg bg-red-50 divide-y bg-markready bg-fill bg-no-repeat bg-center">
            <div className="p-2 flex items-start justify-between space-x-1">
              <div className="flex flex-wrap justify-between items-start w-full">
                <div>
                  <div className="flex items-center space-x-1">
                    <div className="text-xs font-medium text-theme-dark max-w-[120px] truncate">
                      Hunter Pop
                    </div>
                    <div className="text-xs text-gray-400">
                      KHK4EYFW
                    </div>
                  </div>
                  <div className="text-xs text-gray-500 select-all">
                    455-57-6644
                  </div>
                </div>
                <div className="flex items-center space-x-1">
                  <div className="px-1 py-0.5 text-red-600 bg-red-100 font-medium rounded text-xs">
                    In 5mins
                  </div>
                </div>
              </div>
              <Menu as="div" className="relative">
                <Menu.Button className="py-0.5">
                  <DotsVerticalIcon className="h-4 w-4 text-theme-dark" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div class="py-1" role="none">
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Unmark Ready
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Driver
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Member
                      </a>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="p-2 grid grid-cols-2 gap-2">
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-yellow-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-red-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-start space-x-2">
                <div>
                  <OfficeBuildingIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs truncate">
                  Love Trans INC
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <TruckIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  WAV, 13 Miles
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <PlusIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  Logisticare
                </div>
              </div>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <TagIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Fetch ETA
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <DocumentTextIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Company Notes
                  </div>
                </div>
              </Link>
            </div>
            <div className="p-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <div className="flex flex-shrink-0">
                    <img
                        alt="driver"
                      src="/img/driver.jpeg"
                      className="h-8 w-8 rounded-full object-cover"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-medium text-theme-dark w-28 truncate">
                      Gilbert Miller
                    </div>
                    <div className="text-xs text-gray-500 select-all">
                      307-82-0016
                    </div>
                  </div>
                </div>
                <div className="hidden">
                  <button className="px-2 py-1 border text-xs text-white bg-blue-600 rounded font-medium focus:ring-2 ring-offset-2 ring-blue-500">
                    Re-Assign
                  </button>
                </div>
                <div className="space-x-2 flex items-center">
                  <div className="px-1 py-0.5 text-blue-600 bg-blue-100 font-medium rounded text-xs">
                    Offered
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border shadow rounded-lg bg-red-50 divide-y bg-markready bg-fill bg-no-repeat bg-center">
            <div className="p-2 flex items-start justify-between space-x-1">
              <div className="flex flex-wrap justify-between items-start w-full">
                <div>
                  <div className="flex items-center space-x-1">
                    <div className="text-xs font-medium text-theme-dark max-w-[120px] truncate">
                      Hunter Pop
                    </div>
                    <div className="text-xs text-gray-400">
                      KHK4EYFW
                    </div>
                  </div>
                  <div className="text-xs text-gray-500 select-all">
                    455-57-6644
                  </div>
                </div>
                <div className="flex items-center space-x-1">
                  <div className="px-1 py-0.5 text-red-600 bg-red-100 font-medium rounded text-xs">
                    In 5mins
                  </div>
                </div>
              </div>
              <Menu as="div" className="relative">
                <Menu.Button className="py-0.5">
                  <DotsVerticalIcon className="h-4 w-4 text-theme-dark" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div class="py-1" role="none">
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Unmark Ready
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Driver
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Member
                      </a>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="p-2 grid grid-cols-2 gap-2">
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-yellow-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-red-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-start space-x-2">
                <div>
                  <OfficeBuildingIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs truncate">
                  Love Trans INC
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <TruckIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  WAV, 13 Miles
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <PlusIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  Logisticare
                </div>
              </div>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <TagIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Fetch ETA
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <DocumentTextIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Company Notes
                  </div>
                </div>
              </Link>
            </div>
            <div className="p-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <div className="flex flex-shrink-0">
                    <img
                        alt="driver"
                      src="/img/driver.jpeg"
                      className="h-8 w-8 rounded-full object-cover"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-medium text-theme-dark w-28 truncate">
                      Gilbert Miller
                    </div>
                    <div className="text-xs text-gray-500 select-all">
                      307-82-0016
                    </div>
                  </div>
                </div>
                <div className="hidden">
                  <button className="px-2 py-1 border text-xs text-white bg-blue-600 rounded font-medium focus:ring-2 ring-offset-2 ring-blue-500">
                    Re-Assign
                  </button>
                </div>
                <div className="space-x-2 flex items-center">
                  <div className="px-1 py-0.5 text-blue-600 bg-blue-100 font-medium rounded text-xs">
                    Offered
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border shadow rounded-lg bg-red-50 divide-y bg-markready bg-fill bg-no-repeat bg-center">
            <div className="p-2 flex items-start justify-between space-x-1">
              <div className="flex flex-wrap justify-between items-start w-full">
                <div>
                  <div className="flex items-center space-x-1">
                    <div className="text-xs font-medium text-theme-dark max-w-[120px] truncate">
                      Hunter Pop
                    </div>
                    <div className="text-xs text-gray-400">
                      KHK4EYFW
                    </div>
                  </div>
                  <div className="text-xs text-gray-500 select-all">
                    455-57-6644
                  </div>
                </div>
                <div className="flex items-center space-x-1">
                  <div className="px-1 py-0.5 text-red-600 bg-red-100 font-medium rounded text-xs">
                    In 5mins
                  </div>
                </div>
              </div>
              <Menu as="div" className="relative">
                <Menu.Button className="py-0.5">
                  <DotsVerticalIcon className="h-4 w-4 text-theme-dark" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div class="py-1" role="none">
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Unmark Ready
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Driver
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Member
                      </a>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="p-2 grid grid-cols-2 gap-2">
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-yellow-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-red-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-start space-x-2">
                <div>
                  <OfficeBuildingIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs truncate">
                  Love Trans INC
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <TruckIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  WAV, 13 Miles
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <PlusIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  Logisticare
                </div>
              </div>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <TagIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Fetch ETA
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <DocumentTextIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Company Notes
                  </div>
                </div>
              </Link>
            </div>
            <div className="p-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <div className="flex flex-shrink-0">
                    <img
                        alt="driver"
                      src="/img/driver.jpeg"
                      className="h-8 w-8 rounded-full object-cover"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-medium text-theme-dark w-28 truncate">
                      Gilbert Miller
                    </div>
                    <div className="text-xs text-gray-500 select-all">
                      307-82-0016
                    </div>
                  </div>
                </div>
                <div className="hidden">
                  <button className="px-2 py-1 border text-xs text-white bg-blue-600 rounded font-medium focus:ring-2 ring-offset-2 ring-blue-500">
                    Re-Assign
                  </button>
                </div>
                <div className="space-x-2 flex items-center">
                  <div className="px-1 py-0.5 text-blue-600 bg-blue-100 font-medium rounded text-xs">
                    Offered
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[95%] sm:w-80 h-full bg-white border rounded shadow flex flex-col flex-shrink-0" id="activeTrips">
        <div className="flex bg-gray-50 rounded-t justify-between p-1">
          <div className="text-sm text-theme-dark font-bold">
            Active
          </div>
          <div className="rounded text-xs px-1 py-0.5 bg-red-100 text-red-500 font-medium">
            02
          </div>
        </div>
        <div className="flex flex-col flex-1 overflow-y-auto p-2 space-y-2">
          <div className="border shadow rounded-lg bg-white divide-y ">
            <div className="p-2 flex items-start justify-between space-x-1">
              <div className="flex flex-wrap justify-between items-start w-full">
                <div>
                  <div className="flex items-center space-x-1">
                    <div className="text-xs font-medium text-theme-dark max-w-[120px] truncate">
                      Hunter Pop
                    </div>
                    <div className="text-xs text-gray-400">
                      KHK4EYFW
                    </div>
                  </div>
                  <div className="text-xs text-gray-500 select-all">
                    455-57-6644
                  </div>
                </div>
                <div className="flex items-center space-x-1">
                  <div className="px-1 py-0.5 text-blue-600 bg-blue-100 font-medium rounded text-xs">
                    Onway
                  </div>
                </div>
              </div>
              <Menu as="div" className="relative">
                <Menu.Button className="py-0.5">
                  <DotsVerticalIcon className="h-4 w-4 text-theme-dark" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div class="py-1" role="none">
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Unmark Ready
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Driver
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Member
                      </a>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="p-2 grid grid-cols-2 gap-2">
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-yellow-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-red-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <OfficeBuildingIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs truncate">
                  Love Trans INC
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <TruckIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  WAV, 13 Miles
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <PlusIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  Logisticare
                </div>
              </div>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <TagIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Fetch ETA
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <DocumentTextIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Company Notes
                  </div>
                </div>
              </Link>
            </div>
            <div className="p-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <div className="flex flex-shrink-0">
                    <img
                        alt="driver"
                      src="/img/driver.jpeg"
                      className="h-8 w-8 rounded-full object-cover"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-medium text-theme-dark w-28 truncate">
                      Gilbert Miller
                    </div>
                    <div className="text-xs text-gray-500 select-all">
                      307-82-0016
                    </div>
                  </div>
                </div>
                <div className="hidden">
                  <button className="px-2 py-1 border text-xs text-white bg-blue-600 rounded font-medium focus:ring-2 ring-offset-2 ring-blue-500">
                    Re-Assign
                  </button>
                </div>
                <div className="space-x-2 flex items-center">
                  <button className="px-2 py-1 border text-xs text-white bg-blue-600 rounded font-medium focus:ring-2 ring-offset-2 ring-blue-500">
                    Details
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="border shadow rounded-lg bg-white divide-y ">
            <div className="p-2 flex items-start justify-between space-x-1">
              <div className="flex flex-wrap justify-between items-start w-full">
                <div>
                  <div className="flex items-center space-x-1">
                    <div className="text-xs font-medium text-theme-dark max-w-[120px] truncate">
                      Hunter Pop
                    </div>
                    <div className="text-xs text-gray-400">
                      KHK4EYFW
                    </div>
                  </div>
                  <div className="text-xs text-gray-500 select-all">
                    455-57-6644
                  </div>
                </div>
                <div className="flex items-center space-x-1">
                  <div className="px-1 py-0.5 text-blue-600 bg-blue-100 font-medium rounded text-xs">
                    Onway
                  </div>
                </div>
              </div>
              <Menu as="div" className="relative">
                <Menu.Button className="py-0.5">
                  <DotsVerticalIcon className="h-4 w-4 text-theme-dark" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div class="py-1" role="none">
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Unmark Ready
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Driver
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Member
                      </a>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="p-2 grid grid-cols-2 gap-2">
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-yellow-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-red-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <OfficeBuildingIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs truncate">
                  Love Trans INC
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <TruckIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  WAV, 13 Miles
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <PlusIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  Logisticare
                </div>
              </div>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <TagIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Fetch ETA
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <DocumentTextIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Company Notes
                  </div>
                </div>
              </Link>
            </div>
            <div className="p-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <div className="flex flex-shrink-0">
                    <img
                        alt="driver"
                      src="/img/driver.jpeg"
                      className="h-8 w-8 rounded-full object-cover"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-medium text-theme-dark w-28 truncate">
                      Gilbert Miller
                    </div>
                    <div className="text-xs text-gray-500 select-all">
                      307-82-0016
                    </div>
                  </div>
                </div>
                <div className="hidden">
                  <button className="px-2 py-1 border text-xs text-white bg-blue-600 rounded font-medium focus:ring-2 ring-offset-2 ring-blue-500">
                    Re-Assign
                  </button>
                </div>
                <div className="space-x-2 flex items-center">
                  <button className="px-2 py-1 border text-xs text-white bg-blue-600 rounded font-medium focus:ring-2 ring-offset-2 ring-blue-500">
                    Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[95%] sm:w-80 h-full bg-white border rounded shadow flex flex-col flex-shrink-0" id="scheduledTrips">
        <div className="flex bg-gray-50 rounded-t justify-between p-1">
          <div className="text-sm text-theme-dark font-bold">
            Scheduled
          </div>
          <div className="rounded text-xs px-1 py-0.5 bg-red-100 text-red-500 font-medium">
            01
          </div>
        </div>
        <div className="flex flex-col flex-1 overflow-y-auto p-2 space-y-2">
          <div className="border shadow rounded-lg bg-white divide-y ">
            <div className="p-2 flex items-start justify-between space-x-1">
              <div className="flex flex-wrap justify-between items-start w-full">
                <div>
                  <div className="flex items-center space-x-1">
                    <div className="text-xs font-medium text-theme-dark max-w-[120px] truncate">
                      Hunter Pop
                    </div>
                    <div className="text-xs text-gray-400">
                      KHK4EYFW
                    </div>
                  </div>
                  <div className="text-xs text-gray-500 select-all">
                    455-57-6644
                  </div>
                </div>
              </div>
              <Menu as="div" className="relative">
                <Menu.Button className="py-0.5">
                  <DotsVerticalIcon className="h-4 w-4 text-theme-dark" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div class="py-1" role="none">
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Unmark Ready
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Driver
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Member
                      </a>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="p-2 grid grid-cols-2 gap-2">
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-yellow-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-red-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-start space-x-2">
                <div>
                  <OfficeBuildingIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs truncate">
                  Love Trans INC
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <TruckIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  WAV, 13 Miles
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <PlusIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  Logisticare
                </div>
              </div>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <TagIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Fetch ETA
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <DocumentTextIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Company Notes
                  </div>
                </div>
              </Link>
            </div>
            <div className="p-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <div className="flex flex-shrink-0">
                    <img
                        alt="driver"
                      src="/img/driver.jpeg"
                      className="h-8 w-8 rounded-full object-cover"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-medium text-theme-dark w-28 truncate">
                      Gilbert Miller
                    </div>
                    <div className="text-xs text-gray-500 select-all">
                      307-82-0016
                    </div>
                  </div>
                </div>
                <div className="hidden">
                  <button className="px-2 py-1 border text-xs text-white bg-blue-600 rounded font-medium focus:ring-2 ring-offset-2 ring-blue-500">
                    Re-Assign
                  </button>
                </div>
                <div className="space-x-2 flex items-center">
                  <div className="px-1 py-0.5 text-blue-600 bg-blue-100 font-medium rounded text-xs">
                    Offered
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[95%] sm:w-80 h-full bg-white border rounded shadow flex flex-col flex-shrink-0" id="canceledTrips">
        <div className="flex bg-gray-50 rounded-t justify-between p-1">
          <div className="text-sm text-theme-dark font-bold">
            Canceled
          </div>
          <div className="rounded text-xs px-1 py-0.5 bg-red-100 text-red-500 font-medium">
            01
          </div>
        </div>
        <div className="flex flex-col flex-1 overflow-y-auto p-2 space-y-2">
          <div className="border shadow rounded-lg bg-white divide-y ">
            <div className="p-2 flex items-start justify-between space-x-1">
              <div className="flex flex-wrap justify-between items-start w-full">
                <div>
                  <div className="flex items-center space-x-1">
                    <div className="text-xs font-medium text-theme-dark max-w-[120px] truncate">
                      Hunter Pop
                    </div>
                    <div className="text-xs text-gray-400">
                      KHK4EYFW
                    </div>
                  </div>
                  <div className="text-xs text-gray-500 select-all">
                    455-57-6644
                  </div>
                </div>
              </div>
              <Menu as="div" className="relative">
                <Menu.Button className="py-0.5">
                  <DotsVerticalIcon className="h-4 w-4 text-theme-dark" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div class="py-1" role="none">
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Unmark Ready
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Driver
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Member
                      </a>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="p-2 grid grid-cols-2 gap-2">
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-yellow-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-red-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-start space-x-2">
                <div>
                  <OfficeBuildingIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs truncate">
                  Love Trans INC
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <TruckIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  WAV, 13 Miles
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <PlusIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  Logisticare
                </div>
              </div>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <TagIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Fetch ETA
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <DocumentTextIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Company Notes
                  </div>
                </div>
              </Link>
            </div>
            <div className="p-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <div className="flex flex-shrink-0">
                    <img
                        alt="driver"
                      src="/img/driver.jpeg"
                      className="h-8 w-8 rounded-full object-cover"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-medium text-theme-dark w-28 truncate">
                      Gilbert Miller
                    </div>
                    <div className="text-xs text-gray-500 select-all">
                      307-82-0016
                    </div>
                  </div>
                </div>
                <div className="hidden">
                  <button className="px-2 py-1 border text-xs text-white bg-blue-600 rounded font-medium focus:ring-2 ring-offset-2 ring-blue-500">
                    Re-Assign
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[95%] sm:w-80 h-full bg-white border rounded shadow flex flex-col flex-shrink-0" id="completedTrips">
        <div className="flex bg-gray-50 rounded-t justify-between p-1">
          <div className="text-sm text-theme-dark font-bold">
            Completed
          </div>
          <div className="rounded text-xs px-1 py-0.5 bg-red-100 text-red-500 font-medium">
            01
          </div>
        </div>
        <div className="flex flex-col flex-1 overflow-y-auto p-2 space-y-2">
          <div className="border shadow rounded-lg bg-white divide-y ">
            <div className="p-2 flex items-start justify-between space-x-1">
              <div className="flex flex-wrap justify-between items-start w-full">
                <div>
                  <div className="flex items-center space-x-1">
                    <div className="text-xs font-medium text-theme-dark max-w-[120px] truncate">
                      Hunter Pop
                    </div>
                    <div className="text-xs text-gray-400">
                      KHK4EYFW
                    </div>
                  </div>
                  <div className="text-xs text-gray-500 select-all">
                    455-57-6644
                  </div>
                </div>
              </div>
              <Menu as="div" className="relative">
                <Menu.Button className="py-0.5">
                  <DotsVerticalIcon className="h-4 w-4 text-theme-dark" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div class="py-1" role="none">
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Unmark Ready
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Driver
                      </a>
                      <a
                        href="/#"
                        class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        Text Member
                      </a>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="p-2 grid grid-cols-2 gap-2">
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-yellow-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex items-start space-x-2">
                  <div>
                    <LocationMarkerIcon className="h-4 w-4 text-red-500" />
                  </div>
                  <div className="text-xs">
                    <div className="text-theme-dark">
                      1302 Laurel St, Indianapolis, IN 46203, USA
                    </div>
                    <div className="text-gray-400">
                      20 September 2021 <span>3:18</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-start space-x-2">
                <div>
                  <OfficeBuildingIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs truncate">
                  Love Trans INC
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <TruckIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  WAV, 13 Miles
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <div>
                  <PlusIcon className="h-4 w-4 text-blue-500" />
                </div>
                <div className="text-theme-dark text-xs">
                  Logisticare
                </div>
              </div>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <TagIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Fetch ETA
                  </div>
                </div>
              </Link>
              <Link to="">
                <div className="inline-flex items-start space-x-2 cursor-pointer">
                  <div>
                    <DocumentTextIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-blue-500 text-xs">
                    Company Notes
                  </div>
                </div>
              </Link>
            </div>
            <div className="p-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <div className="flex flex-shrink-0">
                    <img
                        alt="driver"
                      src="/img/driver.jpeg"
                      className="h-8 w-8 rounded-full object-cover"
                    />
                  </div>
                  <div>
                    <div className="text-xs font-medium text-theme-dark w-28 truncate">
                      Gilbert Miller
                    </div>
                    <div className="text-xs text-gray-500 select-all">
                      307-82-0016
                    </div>
                  </div>
                </div>
                <div className="hidden">
                  <button className="px-2 py-1 border text-xs text-white bg-blue-600 rounded font-medium focus:ring-2 ring-offset-2 ring-blue-500">
                    Re-Assign
                  </button>
                </div>
                <div className="space-x-2 flex items-center">
                  <div className="px-1 py-0.5 text-blue-600 bg-blue-100 font-medium rounded text-xs">
                    Completed
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  );
}
