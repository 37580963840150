const { SERVER_API_URL } = require("../constant");

const fetchCompaniesList = () => {
  return fetch(`${SERVER_API_URL}/companies`, {
    // headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then((res) => res.json())
    .catch((error) => console.log("Get Companies List Error", error));
};

const fetchAlarmingTripsList = (id) => {
  return fetch(`${SERVER_API_URL}/assigns/alarmingtrips`, {
    // headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then((res) => res.json())
    .catch((error) => console.log("Get Alarming Trips  List Error", error));
};

const fetchTotalTripsList = (date) => {
  return fetch(`${SERVER_API_URL}/assigns/trips?scheduleTime=${date}`, {
    // headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then((res) => res.json())
    .catch((error) => console.log("Get Total Trip List Error", error));
};

// const scheduleSms = (payload) => {
//   return fetch(`${SERVER_API_URL}/scheduleSms`, {
//     method: "post",
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem("token")}`,
//       "content-type": "application/json",
//     },
//     body: JSON.stringify(payload),
//   })
//     .then((res) => res.json())
//     .catch((error) => console.log("Schedule SMS  Error", error));
// };

export { fetchCompaniesList, fetchTotalTripsList, fetchAlarmingTripsList };
