import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import {
  PencilAltIcon,
} from "@heroicons/react/outline";
import { messageService } from "./../utills/shared.service";


function Profile() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [user, setUser] = useState(null);

  useEffect(() => {
    const subscription = messageService.getMessage().subscribe((message) => {
      if (message) {
        setUser(message);
      } else {
        setUser(null);
      }
    });

    return () => {
      console.log("Profile Destroy");
      subscription.unsubscribe();
    };
  }, []);

  const onSubmit = (data) => {
    console.log("Form: ", data);
    // changePassword(data).then((res) => {
    //   if (res.success) {
    //     Swal.fire({
    //       title: "Alert!",
    //       text: res.message,
    //       icon: "success",
    //       confirmButtonColor: "#323484",
    //     });
    //     reset();
    //   } else {
    //     Swal.fire({
    //       title: "Alert!",
    //       text: res.message,
    //       icon: "warning",
    //       confirmButtonColor: "#323484",
    //     });
    //   }
    // });
  };

  return (
    <div className="p-4 space-y-4 flex flex-col flex-1 overflow-y-auto min-w-0">
      <div className="p-4 border bg-white rounded-lg shadow w-full max-w-3xl">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12">
              <h2 className="text-2xl font-bold leading-7 text-theme-dark sm:text-3xl">
                Profile
              </h2>
              <p className="text-sm text-gray-500">
                This information will be displayed publicly so be careful what
                you share.
              </p>
            </div>
            <div className="col-span-12">
              <div className="space-y-1">
                <label
                  htmlFor="company-website"
                  className="block text-xs font-medium text-theme-dark"
                >
                  Photo
                </label>
                <div className="relative h-14 w-14 rounded-full">
                  <img
                  alt="driver"
                    src="/img/driver.jpeg"
                    className="rounded-full h-full w-full object-cover"
                  />
                  <button className="absolute -right-1 bottom-0 rounded-full bg-white/80 h-6 w-6 flex items-center justify-center shadow">
                    <PencilAltIcon className="h-4 w-4 text-blue-500" />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-6">
              <div className="space-y-1">
                <label
                  htmlFor="company-website"
                  className="block text-xs font-medium text-theme-dark"
                >
                  Display Name
                </label>
                <input
                  name="displayName"
                  type="text"
                  className={`${
                    errors.displayName ? "ring-2 ring-red-500" : ""
                  } border-gray-200 rounded-md w-full text-sm`}
                  {...register("displayName", { required: true })}
                />
              </div>
            </div>
            <div className="col-span-12 sm:col-span-6">
              <div className="space-y-1">
                <label
                  htmlFor="company-website"
                  className="block text-xs font-medium text-theme-dark"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  name="emailAddress"
                  className={`${
                    errors.emailAddress ? "ring-2 ring-red-500" : ""
                  } border-gray-200 rounded-md w-full text-sm focus:ring-0 focus:border-gray-200`}
                  {...register("emailAddress", { required: true })}
                  value="admin@yamsol.com"
                  readOnly
                />
              </div>
            </div>
            <div className="col-span-12 sm:col-span-6">
              <div className="space-y-1">
                <label
                  htmlFor="company-website"
                  className="block text-xs font-medium text-theme-dark"
                >
                  Address
                </label>
                <input
                  type="text"
                  name="address"
                  className={`${
                    errors.address ? "ring-2 ring-red-500" : ""
                  } border-gray-200 rounded-md w-full text-sm`}
                  {...register("address", { required: true })}
                />
              </div>
            </div>
            <div className="col-span-12 sm:col-span-6">
              <div className="space-y-1">
                <label
                  htmlFor="company-website"
                  className="block text-xs font-medium text-theme-dark"
                >
                  Timezone
                </label>
                <select
                  name="timeZone"
                  className={`${
                    errors.timeZone ? "ring-2 ring-red-500" : ""
                  } border-gray-200 rounded-md w-full text-sm`}
                  {...register("timeZone", { required: true })}
                >
                  <option>
                    United States of America (USA) - New York (GMT-04:00)
                  </option>
                </select>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-6">
              <div className="space-y-1">
                <label
                  htmlFor="company-website"
                  className="block text-xs font-medium text-theme-dark"
                >
                  Contact Number
                </label>
                <input
                  type="tel"
                  name="contactNumber"
                  className={`${
                    errors.contactNumber ? "ring-2 ring-red-500" : ""
                  } border-gray-200 rounded-md w-full text-sm`}
                  {...register("contactNumber", { required: true })}
                />
              </div>
            </div>
            <div className="col-span-12 flex justify-end">
              <div>
                <button
                  type="submit"
                  className="rounded-md border py-2 px-4 text-white bg-blue-600 font-medium focus:ring-2 ring-offset-2 ring-blue-500 text-xs"
                >
                  Update Profile
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Profile;
