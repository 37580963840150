import React, { useRef, useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/common/nav/Navbar";
import Header from "./components/common/header/Header";
import Footer from "./components/common/footer/Footer";
import Dashboard from "./components/dashboard/Dashboard"
import AllTrips from "./components/all-trips/AllTrips"
import Operations from "./components/operations/Operations"
import AlarmingTrips from "./components/alarming-trips/AlarmingTrips"
import Signin from "./components/auth/signin";
import Profile from "./components/profile/profile";
import NoMatch from "./components/common/404/NoMatch";
import useToken from "./customHook/useToken";
import fetchIntercept from "fetch-intercept";
import { validateAuthToken } from "./components/utills/networkUtils/authentication";
import { messageService } from "./components/utills/shared.service";
import LoadingBar from "react-top-loading-bar";
import Overlay from "./components/common/overlay/Overlay";

import "./App.css";

export default function App() {
  const ref = useRef(null);
  const { token, setToken } = useToken();
  const [isShowNav, setShowNav] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);


  fetchIntercept.register({
    request: function (url, config) {
      startLoader();
      config.headers = {
        ...config.headers,
        Authorization: `JWT ${localStorage.getItem("token")}`,
      };
      return [url, config];
    },

    requestError: function (error) {
      return Promise.reject(error);
    },

    response: function (response) {
      stopLoader();
      return response;
    },

    responseError: function (error) {
      return Promise.reject(error);
    },
  });

  const startLoader = async () => {
    if (ref.current) {
      ref.current.continuousStart();
      setShowOverlay(true);
    }
  };

  const stopLoader = async () => {
    if (ref.current) {
      ref.current.complete();
      setShowOverlay(false);
    }
  };

  const verifyToken = async () => {
    token &&
      validateAuthToken().then((result) => {
        if (result && result.success && result.user) {
          messageService.sendMessage(result.user);
        } else {
          setToken(null);
        }
      });
  };

  useEffect(() => {
    verifyToken();
  }, []);

  if (!token) {
    return <Signin setToken={setToken} />;
  }

  return (
    <Router>     
      <div className="flex h-screen bg-gray-100">
      <Navbar setToken={setToken} isShowNav={isShowNav} setShowNav={setShowNav} />
        <main className="flex flex-1 flex-col justify-between w-full overflow-hidden">
          <Header  setShowNav={setShowNav} />

          <LoadingBar color="#809AE7" height={4} ref={ref} />
          <Overlay show={showOverlay} />
          <Switch className="p-8 m-8">
            <Route exact path="/">
              <Dashboard />
            </Route>
            <Route exact path="/all-trips">
              <AllTrips />
            </Route>
            <Route exact path="/operations">
              <Operations />
            </Route>
            <Route exact path="/alarming-trips">
              <AlarmingTrips />
            </Route>
            <Route exact path="/profile">
              <Profile />
            </Route>
            <Route>
              <NoMatch />
            </Route>
          </Switch>
          <Footer/>
        </main>
      </div>
    </Router>
  );
}
