import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  DocumentTextIcon,
  DotsVerticalIcon,
  OfficeBuildingIcon,
  LocationMarkerIcon,
  TagIcon,
  TruckIcon,
} from "@heroicons/react/outline";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { fetchAlarmingTripsList } from "./../utills/networkUtils/companies";
import Swal from "sweetalert2";
import Moment from "react-moment";

export default function AlarmingTrips() {
  const [tripsList, setTripList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getAlarmingTripsList();
  }, []);

  const getAlarmingTripsList = () => {
    fetchAlarmingTripsList().then((result) => {
      if (result && result.assigns) {
        setTripList(result.assigns);
      } else {
        Swal.fire("Alert", "Something went wrong!", "warning");
        setTripList([]);
      }
    });
  };

  // Search
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="p-4 space-y-4 flex flex-col flex-1 overflow-y-auto min-w-0">
      <div className="md:flex justify-between items-center space-y-2  md:space-y-0">
        <div className="sm:flex items-center space-y-2 sm:space-y-0 sm:space-x-2">
          <div className="relative">
            <input
              type="text"
              className="w-64 text-sm rounded"
              placeholder="Search ..."
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <div>
            <Link to="/schedule-message">
              <button
                type="button"
                className="hidden rounded-md border w-full py-2 px-4 text-white bg-blue-600 font-medium focus:ring-2 ring-offset-2 ring-blue-500 text-sm"
              >
                Button
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-5">
        {tripsList.length>0  && tripsList
          .filter(
            (item) =>
              item.priorityClient.displayName
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              item.priorityClient.contactNumber
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              item.driver.displayName
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              item.driver.contactNumber
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              item.tripId.toLowerCase().includes(searchTerm.toLowerCase()) ||
              item.jobDestinationAddress
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              item.jobOriginAddress
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              item.jobStatus.toLowerCase().includes(searchTerm.toLowerCase()) ||
              item.companyType.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((item) => (
            <div
              key={item._id}
              className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 "
            >
              <div className="border shadow rounded-lg bg-white divide-y bg-markready bg-fill bg-no-repeat bg-center h-full flex flex-col justify-between">
                <div className="p-2 flex items-start justify-between space-x-1">
                  <div className="flex flex-wrap justify-between items-start w-full">
                    <div>
                      <div className="flex items-center space-x-1">
                        <div className="text-xs font-medium text-theme-dark max-w-[120px] truncate">
                          {item.priorityClient.displayName}
                        </div>
                        <div className="text-xs text-gray-400">
                          {item.tripId}
                        </div>
                      </div>
                      <div className="text-xs text-gray-500 select-all">
                        {item.priorityClient.contactNumber}
                      </div>
                    </div>
                    <div className="flex items-center space-x-1">
                      <div className="px-1 py-0.5 text-red-600 bg-red-100 font-medium rounded text-xs">
                        In 5mins
                      </div>
                    </div>
                  </div>
                  <Menu as="div" className="relative">
                    <Menu.Button className="py-0.5">
                      <DotsVerticalIcon className="h-4 w-4 text-theme-dark" />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="menu-button"
                        tabindex="-1"
                      >
                        <div class="py-1" role="none">
                          <a
                            href="#/"
                            class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                            role="menuitem"
                            tabindex="-1"
                            id="menu-item-0"
                          >
                            Mark Ready
                          </a>
                          <a
                            href="#/"
                            class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                            role="menuitem"
                            tabindex="-1"
                            id="menu-item-0"
                          >
                            Text Driver
                          </a>
                          <a
                            href="#/"
                            class="text-gray-700 block px-3 py-1.5 text-xs hover:bg-blue-50"
                            role="menuitem"
                            tabindex="-1"
                            id="menu-item-0"
                          >
                            Text Member
                          </a>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div className="p-2 grid grid-cols-2 gap-2 h-full">
                  <div className="col-span-2">
                    <div className="flex items-start space-x-2">
                      <div>
                        <LocationMarkerIcon className="h-4 w-4 text-yellow-500" />
                      </div>
                      <div className="text-xs">
                        <div className="text-theme-dark">
                          {item.jobOriginAddress}
                        </div>
                        <div className="text-gray-400">
                          <Moment format="LLLL">{item.scheduleTime}</Moment>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2">
                    <div className="flex items-start space-x-2">
                      <div>
                        <LocationMarkerIcon className="h-4 w-4 text-red-500" />
                      </div>
                      <div className="text-xs">
                        <div className="text-theme-dark">
                          {item.jobDestinationAddress}
                        </div>
                        <div className="text-gray-400">
                          <Moment format="LLLL">{item.appointmentTime}</Moment>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-start space-x-2">
                    <div>
                      <OfficeBuildingIcon className="h-4 w-4 text-blue-500" />
                    </div>
                    <div className="text-theme-dark text-xs truncate">
                      {item.companyType}
                    </div>
                  </div>
                  <div className="flex items-start space-x-2">
                    <div>
                      <TruckIcon className="h-4 w-4 text-blue-500" />
                    </div>
                    <div className="text-theme-dark text-xs">
                      {item.tripRequirement}, {item.milage} Miles
                    </div>
                  </div>
                  {/* <div className="flex items-start space-x-2">
                  <div>
                    <PlusIcon className="h-4 w-4 text-blue-500" />
                  </div>
                  <div className="text-theme-dark text-xs">
                    {item.companyType}
                  </div>
                </div> */}
                  <Link to="">
                    <div className="inline-flex items-start space-x-2 cursor-pointer">
                      <div>
                        <TagIcon className="h-4 w-4 text-blue-500" />
                      </div>
                      <div className="text-blue-500 text-xs">Fetch ETA</div>
                    </div>
                  </Link>
                  <Link to="">
                    <div className="inline-flex items-start space-x-2 cursor-pointer">
                      <div>
                        <DocumentTextIcon className="h-4 w-4 text-blue-500" />
                      </div>
                      <div className="text-blue-500 text-xs">Company Notes</div>
                    </div>
                  </Link>
                </div>
                <div className="p-2 h-12 flex flex-shrink-0">
                  <div className="flex items-center justify-between w-full">
                    <div>
                      {item.driver && (
                        <div className="flex items-center space-x-2">
                          <div className="flex flex-shrink-0">
                            <img
                              alt="driver"
                              src={item.driver?.profileImageURL}
                              className="h-8 w-8 rounded-full object-cover"
                            />
                          </div>
                          <div>
                            <div className="text-xs font-medium text-theme-dark w-28 truncate">
                              {item.driver?.displayName}
                            </div>
                            <div className="text-xs text-gray-500 select-all">
                              {item.driver?.contactNumber}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="">
                      <div className="hidden">
                        <button className="px-2 py-1 border text-xs text-white bg-blue-600 rounded font-medium focus:ring-2 ring-offset-2 ring-blue-500">
                          Re-Assign
                        </button>
                      </div>
                      <div className="space-x-2 flex items-center">
                        <div className="px-1 py-0.5 text-blue-600 bg-blue-100 font-medium rounded text-xs">
                          {item.status}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
