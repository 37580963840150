import { NavLink } from "react-router-dom";
import {
  HomeIcon,
  InformationCircleIcon,
  LogoutIcon,
  UserIcon,
  ViewBoardsIcon,
  ViewGridIcon,
  XIcon,
} from "@heroicons/react/outline";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";

export default function Navbar({ isShowNav, setShowNav, setToken }) {
  // extract pathname from location
  const { pathname } = useLocation();

  const handleToggleClick = () => setShowNav((value) => !value);

  const handleLogoutClick = () => {
    Swal.fire({
      title: "Alert!",
      text: "Are you sure you want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#26265f",
      cancelButtonColor: "#bcc9d9",
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then((result) => {
      if (result.isConfirmed) {
        setToken(null);
      }
    });
  };

  return (
    <nav
      className={` ${
        isShowNav ? "" : "hidden"
      } w-64 h-full bg-gray-900 divide-y divide-gray-50 divide-opacity-20 lg:flex flex-col flex-shrink-0 overflow-hidden fixed lg:static z-50`}
    >
      <div className="h-16 flex items-center px-4">
        <div className="flex justify-between items-center w-full">
          <div>
            <img
              src="https://nemtplatform.com/img/logo.png"
              className="h-8 w-auto filter"
              alt=""
            />
          </div>
          <div className="lg:hidden"    onClick={() => handleToggleClick()}>
            <div className="p-1 flex items-center justify-center rounded outline-none focus:outline-none">
              <XIcon className="w-6 h-6 text-white" />
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-8 overflow-y-auto flex flex-col flex-1 justify-between">
        <ul className="space-y-3">
          <li>
            <NavLink
              to="/"
              exact
              // isActive={() => ['/direct-message', '/new-direct-message'].includes(pathname)}
              activeClassName="nav-item-active"
              className={`nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer `}
            >
              <div>
                <HomeIcon className="w-5 h-5" />
              </div>
              <div className="font-medium text-xs">Dashboard</div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/all-trips"
              activeClassName="nav-item-active"
              className={`nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer `}
            >
              <div>
                <ViewGridIcon className="w-5 h-5" />
              </div>
              <div className="font-medium text-xs">All Trips</div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/operations"
              activeClassName="nav-item-active"
              className={`nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer `}
            >
              <div>
                <ViewBoardsIcon className="w-5 h-5" />
              </div>
              <div className="font-medium text-xs">Operations</div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/alarming-trips"
              activeClassName="nav-item-active"
              className={`nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer `}
            >
              <div>
                <InformationCircleIcon className="w-5 h-5" />
              </div>
              <div className="font-medium text-xs">Alarming Trips</div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/profile"
              activeClassName="nav-item-active"
              className={`nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md cursor-pointer `}
            >
              <div>
                <UserIcon className="w-5 h-5" />
              </div>
              <div className="font-medium text-xs">Profile</div>
            </NavLink>
          </li>
        </ul>
        <ul className="space-y-3 mt-3">
          <li onClick={() => handleLogoutClick()}>
            <a href="#/">
              <div className="nav-item flex items-center space-x-4 text-gray-100 py-2 px-3 rounded-md">
                <div>
                  <LogoutIcon className="h-5 w-5" />
                </div>
                <div className="font-medium text-xs">Log Out</div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
