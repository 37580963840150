
import error from '../../assets/404.svg'

export default function NoMatch() {
    return (
        <div className="py-24 flex justify-center items-center p-4">
              <img
                src={error}
                className="h-64 sm:h-72 lg:h-80 xl:h-96 w-auto"
                alt=""
              />
        </div>
        );
    }
