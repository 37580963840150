import { MenuAlt1Icon } from "@heroicons/react/outline";

export default function Header({ setShowNav }) {
  const handleToggleClick = () => setShowNav((value) => !value);

  return (
    <header className="h-12 flex-shrink-0 border-b flex justify-between items-center w-full bg-gray-900">
      <div className="font-medium text-lg text-white tracking-wide px-4">
        Dashboard
      </div>
      <div className="h-full">
        <button
          className="h-full text-white flex justify-center items-center w-12 focus:outline-none outline-none hover:bg-gray-50/20"
          onClick={() => handleToggleClick()}
        >
          <MenuAlt1Icon className="w-6 h-6" />
        </button>
      </div>
    </header>
  );
}
